require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0d12b2ba1f4f2bbc9ecc16e74798f99f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f'] = async function fn_0d12b2ba1f4f2bbc9ecc16e74798f99f(Tablename, UserObjectId) {
return (await Backendless.Data.of(Tablename).find(Backendless.DataQueryBuilder.create().setWhereClause((['listOwner.objectId = \'',UserObjectId,'\''].join(''))).setRelationsDepth(2).setSortBy('created desc').setPageSize(100)))
}

})
define('./functions/1d6bcfb7855c5b2d041d359da5e556a9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1d6bcfb7855c5b2d041d359da5e556a9'] = async function fn_1d6bcfb7855c5b2d041d359da5e556a9() {
var error;


  await Backendless.Data.of('Errors').save( ({ [`code`]: error,[`message`]: error,[`pageName`]: ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) }) );

return 
}

})
define('./functions/d38a65f68621af766cb60c88ef4fcba3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d38a65f68621af766cb60c88ef4fcba3'] = async function fn_d38a65f68621af766cb60c88ef4fcba3(userId, storeId, productId, day) {
return (await Backendless.Data.of('Prices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId = \'',userId,'\' AND store = \'',storeId,'\' AND product = \'',productId,'\' AND created > ',day].join('')))))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async ['onClick'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onClick *//* content */
}));

define('./pages/AddProduct/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var subcategories, mainCategories, units, brand, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  units = (await Promise.all((await Backendless.Data.of('Units').find(Backendless.DataQueryBuilder.create().setSortBy(['name']).setPageSize(100))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'name')) });})));
  ___arguments.context.pageData['unitsCollection'] = units;
  mainCategories = (await Promise.all((await Backendless.Data.of('MainCategories').find(Backendless.DataQueryBuilder.create().setSortBy(['name']).setPageSize(100))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'name')) });})));
  ___arguments.context.pageData['mainCategoriesCollection'] = mainCategories;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('55961ff5570b1a92d35feda34dcf233d'))['unitsCollection'] = units;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('55961ff5570b1a92d35feda34dcf233d'))['mainCategoriesCollection'] = mainCategories;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/AddProduct/components/e68b29c526b9d5eec7e169a34528e859/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newProduct, error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e68b29c526b9d5eec7e169a34528e859')), 'button-loading');
    newProduct = (await Backendless.Data.of('Products').save( ({ [`name`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'name')),[`barcode`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'barcode')),[`amount`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'amount')),[`alcoholVolume`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'alcoholVolume')),[`image`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'productImage')) }) ));
    await Backendless.Data.of('Products').addRelation((getObjectProperty(newProduct, 'objectId')), 'unit', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'selectedUnit'))]);
    await Backendless.Data.of('Products').addRelation((getObjectProperty(newProduct, 'objectId')), 'brand', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'selectedBrand'))]);
    await Backendless.Data.of('Products').addRelation((getObjectProperty(newProduct, 'objectId')), 'category', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'selectedCategory'))]);
    await Backendless.Data.of('Products').addRelation((getObjectProperty(newProduct, 'objectId')), 'subCategory', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'selectedSubCategory'))]);
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['showStatusBar'] = true;

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('ba85b6de166f9d7de5369b357d2f5247', true, false, undefined, (getObjectProperty(error, 'message')), 'warning');

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e68b29c526b9d5eec7e169a34528e859')), 'button-loading', '');

    ;(function() {
      const callback = async () => {
          ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['showStatusBar'] = false;

      };

      const timerId = '';
      const timerDelay = 2000;

      runSetTimeout(timerId, callback, timerDelay)
    })()
    ___arguments.context.pageData['lastAddedProduct'] = ([(getObjectProperty(newProduct, 'name')),' / ',(getObjectProperty(newProduct, 'barcode'))].join(''));
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['brandSearch'] = '';

  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'ProductStatus')) == 1)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/AddProduct/components/5878faff733f4903792dce3c16b661c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDecodeSuccess */
  async ['onDecodeSuccess'](___arguments) {
    var ScannedProduct;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['barcode'] = ___arguments['decodedCode'];
  ScannedProduct = (await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((String('barcode = ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'barcode')))).setPageSize(10)));
  if (!!ScannedProduct.length) {
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['ProductStatus'] = (getObjectProperty((ScannedProduct[0]), 'name'));
  } else {
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['ProductStatus'] = 'Šis Produkts Vēl Nav Pievienots';
  }

  },
  /* handler:onDecodeSuccess */
  /* content */
}))

define('./pages/AddProduct/components/0f2c966516c578d43fd2b242d21680b5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var subcategories, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  subcategories = (await Promise.all((await Backendless.Data.of('Categories').loadRelations(___arguments.value, Backendless.LoadRelationsQueryBuilder.create().setRelationName('subCategories').setPageSize(100).setOffset(0))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'name')) });})));
  ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['subCategoriesCollection'] = subcategories;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AddProduct/components/22e47025046b779d2bc4da4ebb317d3f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AddProduct/components/ae4924781b842f314d2b39717756f782/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    var ScannedProduct;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ScannedProduct = (await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((String('barcode = ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'barcode')))).setPageSize(10)));
  if (!!ScannedProduct.length) {
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['ProductStatus'] = (getObjectProperty((ScannedProduct[0]), 'name'));
  } else {
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['ProductStatus'] = 'Šis Produkts Vēl Nav Pievienots';
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/AddProduct/components/8f4c96a912badc261f91fe1aa252aa31/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, brand, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('8f4c96a912badc261f91fe1aa252aa31')), 'button-loading');
    await Backendless.Data.of('Brands').save( ({ [`brandName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b0cc251295dbf5f90ab7feb6d75bb0a7'), 'zimols')),[`producerName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b0cc251295dbf5f90ab7feb6d75bb0a7'), 'razotajs')) }) );
    brand = (await Promise.all((await Backendless.Data.of('Brands').find(Backendless.DataQueryBuilder.create().setSortBy(['brandName']).setPageSize(100))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'brandName')) });})));
    ___arguments.context.pageData['brandCollection'] = brand;
    ___arguments.context.getComponentDataStoreByUid('b0cc251295dbf5f90ab7feb6d75bb0a7')['zimols'] = '';

  } catch (error) {
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('8f4c96a912badc261f91fe1aa252aa31')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AddProduct/components/af7e7f42c06205966808d218b363668b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var brandsList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  brandsList = (await Promise.all((await Backendless.Data.of('Brands').find(Backendless.DataQueryBuilder.create().setWhereClause((['brandName LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d'), 'brandSearch')),'%\''].join(''))).setSortBy('brandName ASC').setPageSize(10))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'brandName')) });})));
  ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['brandCollection'] = brandsList;
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('0b3c44f21f4c9d3e1dd24568b896a51a', (getObjectProperty((brandsList[0]), 'value')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AddProduct/components/38eba04b1856a8412a3b943b63334b8e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var categories, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  categories = (await Promise.all((await Backendless.Data.of('MainCategories').loadRelations(___arguments.value, Backendless.LoadRelationsQueryBuilder.create().setRelationName('categories').setPageSize(100).setOffset(0))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'name')) });})));
  ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['categoriesCollection'] = categories;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AddProduct/components/76a4eceeb102ce5fc2a34a95faec4bfe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['Product1'] = (await Backendless.Data.of('Products').findById('231BCD98-37DD-408B-854A-222485DF0F45', ));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AddProduct/components/45736cb8afb806f9b222d704b424e952/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
    var error;


  try {
    ___arguments.context.getComponentDataStoreByUid('55961ff5570b1a92d35feda34dcf233d')['productImage'] = ___arguments.uploadedFiles;

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/SMSvalidation/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/SMSvalidation/components/6568e9046d6939c7d6fe5bdbdf4140ac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading');
    ___arguments.context.pageData['transactionId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Phone/sendVerificationCode`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((String('+371') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'phoneNumber'))))));
    console.log(String('+371') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'phoneNumber')));

  } catch (error) {
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading', '');
    if (getObjectProperty(___arguments.context.pageData, 'transactionId')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('003ac94103b549fee2a9f6b1f473725d', 'false');
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d72ca7f505bbcdb23595d8d0b4a467a', 'true');
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SMSvalidation/components/db0e20f2a411dc794b3bd6b928f5274f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('db0e20f2a411dc794b3bd6b928f5274f')), 'button-loading');
    (await Backendless.UserService.getCurrentUser(false))['phoneNumber'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Phone/checkVerificationCode`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'transactionId': (getObjectProperty(___arguments.context.pageData, 'transactionId')),'code': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'code')) })), 'phoneNumber'));
    await Backendless.Data.of('Users').save((await Backendless.UserService.getCurrentUser(false)), true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('addPrice', undefined);

  } catch (error) {
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('db0e20f2a411dc794b3bd6b928f5274f')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SMSvalidation/components/27c35ed46fbdc3223262956d1075a046/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('003ac94103b549fee2a9f6b1f473725d', 'true');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d72ca7f505bbcdb23595d8d0b4a467a', 'false');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StripeTestPmt/components/e5da583967baf53296333e69daf539d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/StripeCheckout/checkout/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'selectedSubName'))))), 'url')), false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/f8ab5226de1946fbb8559e12db693e4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('1a59f3e48ab2743aabb1f0b31d1f7153');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/42548d420a4f5fed2b99c5dd6e0980ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('eef87d95a59476b741b9ebdf292bb69d');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/c788d311ec43e955cc0119939c6bd7da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('1a59f3e48ab2743aabb1f0b31d1f7153');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/cd421f36e07739316afa4cdfeef2430e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('e04f11b7bfede42629b29fe9ac029b83');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/c411bdef5f9bfd9dc8ee32a8674987a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['Modal_1_data'] = '111';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/4597c4c1fd4c918926b488bcb39913f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['Modal_3_data'] = '333';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/TestModals/components/5a0511b81d4449bb3a8fd96612e4310e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('e04f11b7bfede42629b29fe9ac029b83');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Tests/components/0d3444569d235cb21b6dae02bda5b3af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedList'] = (await Backendless.Data.of('FavoriteProducts').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId = \'E57CEF18-6E37-4993-84D4-DC9AFF48A3A0\'').setPageSize(1)));
  ___arguments.context.pageData['selectedProduct'] = (await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause('name LIKE \'Piens%\'').setPageSize(5)));
  await Backendless.Data.of('FavoriteProducts').addRelation(((getObjectProperty(___arguments.context.pageData, 'selectedList'))[0]), 'products', (getObjectProperty(___arguments.context.pageData, 'selectedProduct')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Tests/components/ea02b1e1d7c3e2f38f577fa990d4927a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['newPoints'] = (await Backendless.Counters.addAndGet('testCT', (getObjectProperty(___arguments.context.pageData, 'points'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Tests/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, subCategoriesItem, CategoriesItem, subCatItemToSort, MainCategoryItem, catItemToSort, MainCategories;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  try {
    MainCategories = (await Backendless.Data.of('MainCategories').find(Backendless.DataQueryBuilder.create().setRelationsDepth(2).setRelationsPageSize(100).setSortBy('name').setPageSize(100)));
    ___arguments.context.pageData['MainCat'] = (await Promise.all(MainCategories.map(async MainCategoryItem => {; return ({ [`key`]: (getObjectProperty(MainCategoryItem, 'objectId')),[`label`]: (getObjectProperty(MainCategoryItem, 'name')),[`data`]: undefined,[`children`]: (await asyncListSort((await Promise.all((getObjectProperty(MainCategoryItem, 'categories')).map(async CategoriesItem => {; return ({ [`key`]: (getObjectProperty(CategoriesItem, 'objectId')),[`label`]: (getObjectProperty(CategoriesItem, 'name')),[`data`]: undefined,[`children`]: (await asyncListSort((await Promise.all((getObjectProperty(CategoriesItem, 'subCategories')).map(async subCategoriesItem => {; return ({ [`key`]: (getObjectProperty(subCategoriesItem, 'objectId')),[`label`]: (getObjectProperty(subCategoriesItem, 'name')),[`data`]: undefined });}))), 1, async (subCatItemToSort) => {


     return 'name';
    })) });}))), 1, async (catItemToSort) => {


     return 'name';
    })) });})));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Tests/components/08b11f74872add58a48bcc3351540e19/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MainCat'))

  },
  /* handler:optionsLogic */
  /* handler:onSelect */
  async ['onSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('92a7c6e3ae5789e60665224c805adf9f', (await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((['subCategory = \'',(getObjectProperty(___arguments['selectedItem'], 'key')),'\''].join(''))).setRelationsDepth(1).setSortBy('name').setPageSize(100))));

  },
  /* handler:onSelect */
  /* content */
}))

define('./pages/Tests/components/a516c63923bc1a7f9f41beb5023b823a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Tests/components/ba1b9da578d683c39297050c5fa81bbf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'amount')),(getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'unit')), 'name')),(getObjectProperty(___arguments.context.dataModel, 'alcoholVolume')) == null ? '' : [', Alk. ',(getObjectProperty(___arguments.context.dataModel, 'alcoholVolume')),'%'].join('')].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Tests/components/6f14512203f54c060f8516460bafe8a1/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  ['titleLogic'](___arguments) {
    
  },
  /* handler:titleLogic */
  /* content */
}))

define('./pages/Tests/components/0e6147f1ae531103b1daf14a0fdd132d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var storeItem, item, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['cities'] = (await Backendless.Data.of('Stores').find(Backendless.DataQueryBuilder.create().setProperties('city.name, city.population').setSortBy('city.population desc').setGroupBy('city.name').setPageSize(100).setDistinct(true)));
  console.log(getObjectProperty(___arguments.context.pageData, 'cities'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addPrice/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, stores, item, myLocation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    myLocation = (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })());
    stores = (await Promise.all((await Backendless.Data.of('Stores').find(Backendless.DataQueryBuilder.create().setWhereClause((['distanceOnSphere(\'POINT(',(getObjectProperty(myLocation, 'lng')),' ',(getObjectProperty(myLocation, 'lat')),')\', geoLocationPoint) < ',(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'addPrices_storeSearchRadius'))].join(''))).setProperties((['distanceOnSphere(\'POINT(',(getObjectProperty(myLocation, 'lng')),' ',(getObjectProperty(myLocation, 'lat')),')\', geoLocationPoint) as distance',', *'].join(''))).setSortBy('distance').setPageSize(10))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: [(getObjectProperty(item, 'name')),' (',(getObjectProperty(item, 'address')),')'].join('') });})));
    ___arguments.context.pageData['storesCollection'] = stores;

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('8f01dca3ebfaeb810678bcce51ba714a', true, false, undefined, (getObjectProperty(error, 'message')), 'warning');

  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/addPrice/components/6e956b0d947e0c3e955d1693dd47ec2b/bundle.js', [], () => ({
  /* content */
  /* handler:onDecodeSuccess */
  async ['onDecodeSuccess'](___arguments) {
    var error, products, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.pageData['nameOrBarcode'] = ___arguments['decodedCode'];
    products = (await Promise.all((await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((String('barcode = ') + String(___arguments['decodedCode']))).setProperties(['objectId', 'name', 'amount']).setRelationsDepth(1).setPageSize(1))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: [(getObjectProperty(item, 'name')),', ',(getObjectProperty(item, 'amount')),(getObjectProperty(item, 'unit.name'))].join('') });})));
    ___arguments.context.pageData['productsCollection'] = products;
    ___arguments.context.pageData['selectedProduct'] = (getObjectProperty(products, 'label'));
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('bbfd78a17257f51fee61fad15d29655e', (getObjectProperty((products[0]), 'value')));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDecodeSuccess */
  /* content */
}))

define('./pages/addPrice/components/9dc3046303178e8da477b7b455ab31af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var LbPointsAwarded, error, Product, priceObject, Store;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    if ((getObjectProperty(___arguments.context.pageData, 'loyaltyCardPrice')) == null && (getObjectProperty(___arguments.context.pageData, 'regularPrice')) == null && (getObjectProperty(___arguments.context.pageData, 'discountPrice')) == null) {
      throw (new Error('Nav pievienota kāda no cenām: Regulārā, Akcijas vai Lojalitātes'))
    }
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9dc3046303178e8da477b7b455ab31af')), 'button-loading');
    Store = (await Backendless.Data.of('Stores').findById((getObjectProperty(___arguments.context.pageData, 'selectedStore')), ));
    Product = (await Backendless.Data.of('Products').findById((getObjectProperty(___arguments.context.pageData, 'selectedProduct')), ));
    LbPointsAwarded = (await BackendlessUI.Functions.Custom['fn_d38a65f68621af766cb60c88ef4fcba3']((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), (getObjectProperty(___arguments.context.pageData, 'selectedStore')), (getObjectProperty(___arguments.context.pageData, 'selectedProduct')), (new Date((new Date(([(new Date((new Date())).getMonth() + 1),'/',(new Date((new Date())).getDate()),'/',(new Date((new Date())).getFullYear())].join(''))))).getTime()))) >= 1 ? 0 : (getObjectProperty(Store, 'storeMultipleLb')) * (getObjectProperty(Product, 'productMultipleLb'));
    priceObject = ({ [`regularPrice`]: (getObjectProperty(___arguments.context.pageData, 'regularPrice')),[`loyaltyCardPrice`]: (getObjectProperty(___arguments.context.pageData, 'loyaltyCardPrice')),[`loyaltyCardPriceEndDate`]: (getObjectProperty(___arguments.context.pageData, 'loyaltyCardPriceEndDate')),[`discountPrice`]: (getObjectProperty(___arguments.context.pageData, 'discountPrice')),[`discountPriceEndDate`]: (getObjectProperty(___arguments.context.pageData, 'discountPriceEndDate')),[`onePlusOnePrice`]: (getObjectProperty(___arguments.context.pageData, 'onePlusOnePrice')),[`onePlusOnePriceEndDate`]: (getObjectProperty(___arguments.context.pageData, 'onePlusOnePriceEndDate')),[`categoryDiscountAtCheckout`]: (getObjectProperty(___arguments.context.pageData, 'categoryDiscountAtCheckout')),[`categoryDiscountAtCheckoutEndDate`]: (getObjectProperty(___arguments.context.pageData, 'categoryDiscountAtCheckoutEndDate')),[`quantityPrice1`]: (getObjectProperty(___arguments.context.pageData, 'quantityPrice1')),[`minQuantity1`]: (getObjectProperty(___arguments.context.pageData, 'minQuantity1')),[`quantityPrice1EndDate`]: (getObjectProperty(___arguments.context.pageData, 'quantityPrice1EndDate')),[`quantityPrice2`]: (getObjectProperty(___arguments.context.pageData, 'quantityPrice2')),[`minQuantity2`]: (getObjectProperty(___arguments.context.pageData, 'minQuantity2')),[`quantityPrice2EndDate`]: (getObjectProperty(___arguments.context.pageData, 'quantityPrice2EndDate')),[`isSoldOut`]: (getObjectProperty(___arguments.context.pageData, 'isSoldOut')),[`lbPoints`]: LbPointsAwarded,[`store`]: Store,[`product`]: Product });
    console.log((await Backendless.Data.of('Prices').deepSave( priceObject )));
    await new Promise(r => setTimeout(r, 250 || 0));
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e1866bd5908358a2f9cf8c5b4f179e5e')));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('8f01dca3ebfaeb810678bcce51ba714a', true, false, undefined, (String(['Paldies! ',(getObjectProperty(Product, 'name')),' cenas ir pievienotas. '].join('')) + String(LbPointsAwarded == 0 ? 'Šodien par šo preci, šajā veikalā jau esi saņēmis punktus.' : String('Nopelnītie punkti: ') + String(LbPointsAwarded))), 'warning');
    await new Promise(r => setTimeout(r, 250 || 0));

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('8f01dca3ebfaeb810678bcce51ba714a', true, false, undefined, (((getObjectProperty(error, 'message')).indexOf('Object Id must be provided') !== -1) ? 'Nav izvēlēts Veikals vai Produkts.' : (getObjectProperty(error, 'message'))), 'warning');

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9dc3046303178e8da477b7b455ab31af')), 'button-loading', '');
    ___arguments.context.pageData['previousRegularPrice'] = '';
    ___arguments.context.pageData['previousLoyaltyCardPrice'] = '';
    ___arguments.context.pageData['previousLoyaltyCardPriceEndDate'] = '';
    ___arguments.context.pageData['previousDiscountPrice'] = '';
    ___arguments.context.pageData['previousDiscountPriceEndDate'] = '';
    ___arguments.context.pageData['previousOnePlusOnePrice'] = '';
    ___arguments.context.pageData['previousOnePlusOnePriceEndDate'] = '';
    ___arguments.context.pageData['categoryDiscountAtCheckout'] = '';
    ___arguments.context.pageData['categoryDiscountAtCheckoutEndDate'] = '';
    ___arguments.context.pageData['previousQuantityPrice1'] = '';
    ___arguments.context.pageData['previousMinQuantity1'] = '';
    ___arguments.context.pageData['previousQuantityPrice1EndDate'] = '';
    ___arguments.context.pageData['previousQuantityPrice2'] = '';
    ___arguments.context.pageData['previousMinQuantity2'] = '';
    ___arguments.context.pageData['previousQuantityPrice2EndDate'] = '';
    ___arguments.context.pageData['previousIsSoldOut'] = '';
    ___arguments.context.pageData['regularPrice'] = null;
    ___arguments.context.pageData['loyaltyCardPrice'] = null;
    ___arguments.context.pageData['loyaltyCardPriceEndDate'] = null;
    ___arguments.context.pageData['onePlusOnePrice'] = null;
    ___arguments.context.pageData['onePlusOnePriceEndDate'] = null;
    ___arguments.context.pageData['discountPrice'] = null;
    ___arguments.context.pageData['discountPriceEndDate'] = null;
    ___arguments.context.pageData['categoryDiscountAtCheckout'] = null;
    ___arguments.context.pageData['categoryDiscountAtCheckoutEndDate'] = null;
    ___arguments.context.pageData['quantityPrice1'] = null;
    ___arguments.context.pageData['minQuantity1'] = null;
    ___arguments.context.pageData['quantityPrice1EndDate'] = null;
    ___arguments.context.pageData['quantityPrice2'] = null;
    ___arguments.context.pageData['minQuantity2'] = null;
    ___arguments.context.pageData['quantityPrice2EndDate'] = null;
    ___arguments.context.pageData['isSoldOut'] = null;
    ___arguments.context.pageData['nameOrBarcode'] = null;
    ___arguments.context.pageData['selectedProduct'] = null;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0afb13e580cf57cbb00be3572659a327', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ee25e2b293f268bccddc45e19228d5d', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc3ed2bc1c2a52ef4d15b683dd281288', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e70e48aa680fc0b95b6f20b79956e076', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39bea48d0e865238de92bc974c2da7ca', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f49a4df569d23a108135f85627b7cf2', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('473713d813395d038e29f1cf4cee7d66', false);
    await Backendless.Counters.addAndGet((String('LB_') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'))), LbPointsAwarded);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addPrice/components/a136e7d71c25126dddd701f74ca315cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var prevIsSoldOut, prevMinQuantity2, prevQuantityPrice2, prevMinQuantity1, prevQuantityPrice1, discPriceEndDate, prevDiscountPrice, prevLoyaltyCardPrice, prevRegularPrice, prevPriceCreatedDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentProductLastValues'] = (await Backendless.Data.of('Prices').find(Backendless.DataQueryBuilder.create().setWhereClause((['product = \'',(getObjectProperty(___arguments.context.pageData, 'selectedProduct')),'\' AND store = \'',(getObjectProperty(___arguments.context.pageData, 'selectedStore')),'\''].join(''))).setSortBy('created desc').setPageSize(1)));
  prevPriceCreatedDate = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'created'));
  ___arguments.context.pageData['previousPriceCreatedDate'] = ([(new Date(prevPriceCreatedDate).getDate()),'.',(new Date(prevPriceCreatedDate).getMonth() + 1),'.',(new Date(prevPriceCreatedDate).getFullYear())].join(''));
  prevRegularPrice = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'regularPrice'));
  ___arguments.context.pageData['previousRegularPrice'] = (prevRegularPrice == null ? String('€ ') + String('--.--') : String('€ ') + String(prevRegularPrice));
  prevLoyaltyCardPrice = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'loyaltyCardPrice'));
  ___arguments.context.pageData['previousLoyaltyCardPrice'] = (prevLoyaltyCardPrice == null ? String('€ ') + String('--.--') : String('€ ') + String(prevLoyaltyCardPrice));
  prevDiscountPrice = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'discountPrice'));
  ___arguments.context.pageData['previousDiscountPrice'] = (prevDiscountPrice == null ? String('€ ') + String('--.--') : String('€ ') + String(prevDiscountPrice));
  discPriceEndDate = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'discountPriceEndDate'));
  ___arguments.context.pageData['previousDiscountPriceEndDate'] = (discPriceEndDate == null ? String('--.--.----') : [(new Date(discPriceEndDate).getDate()),'.',(new Date(discPriceEndDate).getMonth() + 1),'.',(new Date(discPriceEndDate).getFullYear())].join(''));
  prevQuantityPrice1 = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'quantityPrice1'));
  ___arguments.context.pageData['previousQuantityPrice1'] = (prevQuantityPrice1 == null ? String('€ ') + String('--.--') : String('€ ') + String(prevQuantityPrice1));
  prevMinQuantity1 = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'minQuantity1'));
  ___arguments.context.pageData['previousMinQuantity1'] = (prevMinQuantity1 == null ? String('---') + String(' gab.') : String(prevMinQuantity1) + String(' gab.'));
  prevQuantityPrice2 = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'quantityPrice2'));
  ___arguments.context.pageData['previousQuantityPrice2'] = (prevQuantityPrice2 == null ? String('€ ') + String('--.--') : String('€ ') + String(prevQuantityPrice2));
  prevMinQuantity2 = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'minQuantity2'));
  ___arguments.context.pageData['previousMinQuantity2'] = (prevMinQuantity2 == null ? String('---') + String(' gab.') : String(prevMinQuantity2) + String(' gab.'));
  prevIsSoldOut = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'currentProductLastValues'))[0]), 'isSoldOut'));
  ___arguments.context.pageData['previousIsSoldOut'] = (prevIsSoldOut == true ? String('Izpārdots') : String('Pieejams'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addPrice/components/bbfd78a17257f51fee61fad15d29655e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var error, prevIsSoldOut, prevMinQuantity2, prevQuantityPrice2, prevMinQuantity1, prevQuantityPrice1, discPriceEndDate, prevDiscountPrice, prevLoyaltyCardPrice, prevRegularPrice, prevPriceCreatedDate;


  console.log(___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/14f1338ad029f487c9dcc8cb18b72aa6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'discountPrice')) >= 0.01) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0afb13e580cf57cbb00be3572659a327', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0afb13e580cf57cbb00be3572659a327', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/a7f577f5d353823e609974d76121784f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'loyaltyCardPrice')) >= 0.01) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ee25e2b293f268bccddc45e19228d5d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ee25e2b293f268bccddc45e19228d5d', false);
  }

  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('Maxima') !== -1) {
      return 'Cena ar Paldies karti';
    } else if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('Rimi') !== -1) {
      return 'Cena ar Mans Rimi karti';
    } else if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('LaTS') !== -1) {
      return 'Cena ar LaTS karti';
    } else if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('Mego') !== -1) {
      return 'Cena ar Mego karti';
    } else if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('Drogas') !== -1) {
      return 'Cena ar Drogas karti';
    } else if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('cenuklubs') !== -1) {
      return 'Cena ar Cenuklubs karti';
    } else if ((getObjectProperty(___arguments.context.pageData, 'selectedStoreLabel')).indexOf('Lidl') !== -1) {
      return 'Cena ar Lidl Plus karti';
    } else {
      return 'Cena ar Lojalitātes karti';
    }
  } else {
    return 'Cena ar Lojalitātes karti';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/addPrice/components/b0fd33ba582b53c78b4d57afb30bcd95/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'onePlusOnePrice')) >= 0.01) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc3ed2bc1c2a52ef4d15b683dd281288', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc3ed2bc1c2a52ef4d15b683dd281288', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/17fe946f97fadbb15c438358c1299a05/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['regularPrice'] = '';
  ___arguments.context.pageData['loyaltyCardPrice'] = '';
  ___arguments.context.pageData['loyaltyCardPriceEndDate'] = '';
  ___arguments.context.pageData['discountPrice'] = '';
  ___arguments.context.pageData['discountPriceEndDate'] = '';
  ___arguments.context.pageData['onePlusOnePrice'] = '';
  ___arguments.context.pageData['onePlusOnePriceEndDate'] = '';
  ___arguments.context.pageData['categoryDiscountAtCheckout'] = '';
  ___arguments.context.pageData['categoryDiscountAtCheckoutEndDate'] = '';
  ___arguments.context.pageData['quantityPrice1'] = '';
  ___arguments.context.pageData['minQuantity1'] = '';
  ___arguments.context.pageData['quantityPrice1EndDate'] = '';
  ___arguments.context.pageData['quantityPrice2'] = '';
  ___arguments.context.pageData['minQuantity2'] = '';
  ___arguments.context.pageData['quantityPrice2EndDate'] = '';
  ___arguments.context.pageData['isSoldOut'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0afb13e580cf57cbb00be3572659a327', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ee25e2b293f268bccddc45e19228d5d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc3ed2bc1c2a52ef4d15b683dd281288', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35fdcd214dcfcdc2656c461e6916dfdb', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0bc7c77fccc3b657cd8b958d14ea5f2c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e70e48aa680fc0b95b6f20b79956e076', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39bea48d0e865238de92bc974c2da7ca', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f49a4df569d23a108135f85627b7cf2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('473713d813395d038e29f1cf4cee7d66', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addPrice/components/b84e4ffc39e580f1811f3c1800fc6522/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'categoryDiscountAtCheckout')) >= 0.01) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35fdcd214dcfcdc2656c461e6916dfdb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35fdcd214dcfcdc2656c461e6916dfdb', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/350b753599de79c9a38f28250f332ea4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'quantityPrice1')) >= 0.01) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e70e48aa680fc0b95b6f20b79956e076', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0bc7c77fccc3b657cd8b958d14ea5f2c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e70e48aa680fc0b95b6f20b79956e076', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0bc7c77fccc3b657cd8b958d14ea5f2c', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/33d186e5e1692317be2d945b29b9f808/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'quantityPrice2')) >= 0.01) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f49a4df569d23a108135f85627b7cf2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('473713d813395d038e29f1cf4cee7d66', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f49a4df569d23a108135f85627b7cf2', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('473713d813395d038e29f1cf4cee7d66', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/bca9d2840c5498850954d5bbacae3697/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'minQuantity1')) >= 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39bea48d0e865238de92bc974c2da7ca', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39bea48d0e865238de92bc974c2da7ca', false);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/9ca4b97507a835560e3a03726da9c796/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error, selectedStore, item, storesCollection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  try {
    storesCollection = (getObjectProperty(___arguments.context.pageData, 'storesCollection'));
    selectedStore = (getObjectProperty(___arguments.context.pageData, 'selectedStore'));
    ___arguments.context.pageData['selectedStoreLabel'] = (getObjectProperty(((await asyncListFilter(storesCollection, async (item) => {
       if ((getObjectProperty(item, 'value')) == selectedStore) {
        return true;
      }


     return ;
    }))[0]), 'label'));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addPrice/components/305d8fa496fc9a2f723aeee759456071/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, item, product, products;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    product = (await Promise.all((await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((['name LIKE \'%',(getObjectProperty(___arguments.context.pageData, 'nameOrBarcode')),'%\' ','AND barcode IS NULL'].join(''))).setRelationsDepth(1).setPageSize(10))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: [(getObjectProperty(item, 'name')),', ',(getObjectProperty(item, 'amount')),(getObjectProperty(item, 'unit.name'))].join('') });})));
    ___arguments.context.pageData['productsCollection'] = product;

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/change-password/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/change-password/components/6568e9046d6939c7d6fe5bdbdf4140ac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'password')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'password2'))) {
      addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading');
      (await Backendless.UserService.getCurrentUser(false))['password'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'password'));
      await Backendless.Data.of('Users').save((await Backendless.UserService.getCurrentUser(true)), true);
      await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('61df250a3d67c848c484e40da78460b1', false, false, undefined, 'Parole nomainīta', 'success');
      await new Promise(r => setTimeout(r, 1500 || 0));
      (function () { window.history.back(); })();
    } else {
      await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('61df250a3d67c848c484e40da78460b1', true, false, undefined, 'Paroles nesakrīt', 'warning');
    }

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('61df250a3d67c848c484e40da78460b1', true, false, undefined, (getObjectProperty(error, 'message')), 'warning');

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/change-password/components/c9dc269e692467e800cff35e95a6f7cb/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('/web/images/appLogos/') + String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'logoFileName')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      ___arguments.context.pageData['priceCountTotal'] = (await Backendless.Data.of('Prices').getObjectCount());
  ___arguments.context.pageData['productCountTotal'] = (await Backendless.Data.of('Products').getObjectCount());

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/leaderboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var LeaderboardParticipants;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  LeaderboardParticipants = (await Backendless.Request.get(`${Backendless.appPath}/services/Leaderboard/getAllParticipants`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.pageData['myPoints'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Leaderboard/getMyPoints`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'name': JSON.stringify((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'))) }).send());
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('5b3574a97e244f7aa161c0a89b95dc58', LeaderboardParticipants);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/lists/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var ProdListForUser, ShoppListForUser, StoreListForUser, User;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  User = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['ProductListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteProducts', (getObjectProperty(User, 'objectId'))));
  ___arguments.context.pageData['StoreListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteStores', (getObjectProperty(User, 'objectId'))));
  ___arguments.context.pageData['ShoppingListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('ShoppingLists', (getObjectProperty(User, 'objectId'))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/lists/components/f7f1b3b1931e364c2527a1645ac59286/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d2e77ed4599c8473919fee97c5e8033e');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/67c2db41c477d26847134f2e2e6f8b27/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('37ac7941871c412f43e70cf8dcb83d71');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/6b4815c496c5b04fbe23f9b3761d4452/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['deleteProductListName'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('57d353bb4fd99c030184d4b4a3b93f74'), 'name'));
  ___arguments.context.pageData['deleteProductListObjectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('57d353bb4fd99c030184d4b4a3b93f74'), 'objectId'));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d85985d3f52ae653024a072151df9648');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/c3e206ee70a98ed23c0672dbc16e0502/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'deleteProductListName'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/lists/components/514f221627afc831bb1625a328f1096e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d85985d3f52ae653024a072151df9648');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/c044a80be9ecdb57986e425af9fe3fe6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('FavoriteProducts').remove( (getObjectProperty(___arguments.context.pageData, 'deleteProductListObjectId')) );
    ___arguments.context.pageData['ProductListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteProducts', (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))));
    await new Promise(r => setTimeout(r, 200 || 0));
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d85985d3f52ae653024a072151df9648');

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/085a055cdc7d28a8b7d5860c3d49cf08/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('4b5d92460197ce3e2e195b0dc1beca87');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/94023e4bbebfee72d086d824b135201c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, User;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    User = (await Backendless.UserService.getCurrentUser(false));
    await Backendless.Data.of('FavoriteStores').setRelation((await Backendless.Data.of('FavoriteStores').save( ({ [`name`]: (getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), 'storeListName')) }) )), 'listOwner', [(getObjectProperty(User, 'objectId'))]);
    await new Promise(r => setTimeout(r, 200 || 0));
    ___arguments.context.pageData['StoreListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteStores', (getObjectProperty(User, 'objectId'))));
    ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['storeListName'] = '';
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('4b5d92460197ce3e2e195b0dc1beca87');

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/bb85e1a764f255d8f85fcc91bb77f49d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('4b5d92460197ce3e2e195b0dc1beca87');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/93d611e11a7edf29dc6c3846ac2ab691/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, User;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    User = (await Backendless.UserService.getCurrentUser(false));
    await Backendless.Data.of('FavoriteProducts').setRelation((await Backendless.Data.of('FavoriteProducts').save( ({ [`name`]: (getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), 'productListName')) }) )), 'listOwner', [(getObjectProperty(User, 'objectId'))]);
    await new Promise(r => setTimeout(r, 200 || 0));
    ___arguments.context.pageData['ProductListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteProducts', (getObjectProperty(User, 'objectId'))));
    ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['productListName'] = '';
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('37ac7941871c412f43e70cf8dcb83d71');

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/84640a1fe0e2ced5ff02b9045641d366/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('37ac7941871c412f43e70cf8dcb83d71');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/8002566b06ad1da79dd5e7fdb539be26/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'deleteStoreListName'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/lists/components/3c31196fd08ac5be3fab192dd956b003/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['deleteStoreListName'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7de1485c4574c47af53ca4ccc6b77a92'), 'name'));
  ___arguments.context.pageData['deleteStoreListObjectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7de1485c4574c47af53ca4ccc6b77a92'), 'objectId'));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d6a7986aa65ee3d85dbea5b1fcfbc12a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/65cbe5ae44f714a6767d909cc996db07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('FavoriteStores').remove( (getObjectProperty(___arguments.context.pageData, 'deleteStoreListObjectId')) );
    ___arguments.context.pageData['StoreListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteStores', (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))));
    await new Promise(r => setTimeout(r, 200 || 0));
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d6a7986aa65ee3d85dbea5b1fcfbc12a');

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/8d194a58d66fac33bdbbb0dfb96854db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d6a7986aa65ee3d85dbea5b1fcfbc12a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/893b8733665f7c94a54a9d78725ccbf5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('eb5f41371c54ce0fa23c886dd4970303');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/86cdd5fc5fd34b29cad4a9cc1ef48822/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, User;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    User = (await Backendless.UserService.getCurrentUser(false));
    await Backendless.Data.of('ShoppingLists').setRelation((await Backendless.Data.of('ShoppingLists').save( ({ [`name`]: (getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), 'shoppingListName')) }) )), 'listOwner', [(getObjectProperty(User, 'objectId'))]);
    await new Promise(r => setTimeout(r, 200 || 0));
    ___arguments.context.pageData['ShoppingListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('ShoppingLists', (getObjectProperty(User, 'objectId'))));
    ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['shoppingListName'] = '';
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('eb5f41371c54ce0fa23c886dd4970303');

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/3aa64fcc071b352fbd710de45c2a8fde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('eb5f41371c54ce0fa23c886dd4970303');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/ac9750a87f9824639fb8dfe390d49c4f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'deleteShoppingListName'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/lists/components/d78917782ee3fa3b10d37bfeadfdd382/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['deleteShoppingListName'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('92de9a2f30bb61955552a60d7514c9fc'), 'name'));
  ___arguments.context.pageData['deleteShoppingListObjectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('92de9a2f30bb61955552a60d7514c9fc'), 'objectId'));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('9cf3bc1eeff5867625eaa16150153c27');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/c2d2a77d13ac24c83963b4d57475fc2c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('ShoppingLists').remove( (getObjectProperty(___arguments.context.pageData, 'deleteShoppingListObjectId')) );
    ___arguments.context.pageData['ShoppingListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('ShoppingLists', (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'))));
    await new Promise(r => setTimeout(r, 200 || 0));
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('9cf3bc1eeff5867625eaa16150153c27');

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/c4efbee9fdf8b5080023ca6beb9fba68/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('9cf3bc1eeff5867625eaa16150153c27');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lists/components/0ab4ba9380d8950830aa5a110167ccda/bundle.js', [], () => ({
  /* content */
  /* handler:onDecodeSuccess */
  async ['onDecodeSuccess'](___arguments) {
    var error, product, list;


  try {
    product = (await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((String('barcode = ') + String(___arguments['decodedCode']))).setRelationsDepth(1).setPageSize(1)));
    ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['scannedProduct'] = (product[0]);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDecodeSuccess */
  /* content */
}))

define('./pages/lists/components/27964eda8919e2394e8276e3dd1f1f61/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var scpr, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  scpr = (getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), 'scannedProduct'));
  console.log(getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), 'scannedProduct'));
  console.log(getObjectProperty(scpr, 'name'));
  console.log(getObjectProperty(scpr, 'category.name'));
  console.log(getObjectProperty(scpr, 'amount'));
  console.log(getObjectProperty(scpr, 'unit.name'));
  console.log(getObjectProperty(((getObjectProperty(scpr, 'subCategory'))[0]), 'name'));
  console.log(getObjectProperty(scpr, 'brand.brandName'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }
  await (async function() {
  	var email = document.getElementById('email')
  	.getElementsByTagName('input')[0];
  	var password = document.getElementById('password')
  	.getElementsByTagName('input')[0];
  	email.setAttribute( "autocomplete", "email");
  	password.setAttribute( "autocomplete", "current-password");
  })();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/login/components/6568e9046d6939c7d6fe5bdbdf4140ac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading');
    ___arguments.context.appData['currentUser'] = (await Backendless.UserService.login( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'email')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'password')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'rememberMe'))  ));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'appOnboardStatus')) == 'Completed' ? 'home' : 'welcome'), undefined);

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('55e261d2f1b2984287cf22eb184224f8', true, false, undefined, (((getObjectProperty(error, 'message')).indexOf('email address must be confirmed first') !== -1) ? 'Epasta adrese nav apstiprināta. Dodies uz savu epastu.' : (getObjectProperty(error, 'message'))), 'error');
    await Backendless.Data.of('Errors').save( ({ [`message`]: (getObjectProperty(error, 'message')),[`code`]: (getObjectProperty(error, 'code')),[`pageName`]: ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()),[`userEmail`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'email')) }) );

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/749862a84e25f7cdc7c7bfb52ed974b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('registration', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/40e81cab20587b4365d113437a23efe9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('recover-password', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/1247ddc53b4d875e9465c4857d3e2c87/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('/web/images/appLogos/') + String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'logoFileName')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/message/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')) == 'uldis.borkus@gmail.com') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d684354babddd8bad0ca309e4ff81ecb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d684354babddd8bad0ca309e4ff81ecb', false);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/profile/components/02c2c397050ac5f6887f626f79bc9fcb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('change-password', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/38bf7c0aeaedf5e65822cc07f06b157f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;


  try {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/3a35c0ab92f1088e18f12e9a40c57fc4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('AddProduct', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')) == 'uldis.borkus@gmail.com') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d684354babddd8bad0ca309e4ff81ecb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d684354babddd8bad0ca309e4ff81ecb', false);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/profile/components/eae169379a24844ea1f56fee7496abef/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/4fe857deb5f5e8f1c49e26048eba655f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('message', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/8483132e2705f912500203eceedb45a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('leaderboard', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/eb3e3fffb7141c11988588943d71313f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('mySubscription', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/recover-password/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/recover-password/components/6568e9046d6939c7d6fe5bdbdf4140ac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading');
    await Backendless.UserService.restorePassword((getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'email')));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('61df250a3d67c848c484e40da78460b1', true, false, undefined, (['Uz ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'email')),' ir nosūtīta pagaidu parole. '].join('')), 'success');
    await new Promise(r => setTimeout(r, 500 || 0));
    (function () { window.history.back(); })();

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('61df250a3d67c848c484e40da78460b1', true, false, undefined, (getObjectProperty(error, 'message')), 'warning');
    await Backendless.Data.of('Errors').save( ({ [`message`]: (getObjectProperty(error, 'message')),[`code`]: (getObjectProperty(error, 'code')),[`pageName`]: ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()),[`userEmail`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'email')) }) );

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/recover-password/components/749862a84e25f7cdc7c7bfb52ed974b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/recover-password/components/c9dc269e692467e800cff35e95a6f7cb/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('/web/images/appLogos/') + String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'logoFileName')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/registration/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/registration/components/6568e9046d6939c7d6fe5bdbdf4140ac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading');
    await Backendless.UserService.register( new Backendless.User({ 'email': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'email')),'password': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abfad221a756d13121da0d3b815d52a8'), 'password')) }) );
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('fa31d065264f647ce6ff3a1ef10377ae', true, false, undefined, 'Konts ir izveidots! Dodies uz savu epastu lai aktivizētu savu kontu.', 'success');
    await new Promise(r => setTimeout(r, 1000 || 0));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('fa31d065264f647ce6ff3a1ef10377ae', true, false, undefined, 'Pēc mirkļa tiksi pārvirzīts uz sākumlapu', 'info');
    await new Promise(r => setTimeout(r, 5500 || 0));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('fa31d065264f647ce6ff3a1ef10377ae', true, false, undefined, (((getObjectProperty(error, 'message')).indexOf('User already exists') !== -1) ? 'Lietotājs ar šādu epastu jau ir reģistrēts.' : (getObjectProperty(error, 'message'))), 'warning');

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6568e9046d6939c7d6fe5bdbdf4140ac')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/registration/components/e6eea64dedb77fdec3894a888a1c84d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/registration/components/42ada31710e4660c55b878730948ec7f/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('/web/images/appLogos/') + String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'logoFileName')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/search/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var storeItem, brandItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['brandsOptions'] = (await Promise.all((await Backendless.Data.of('Stores').find(Backendless.DataQueryBuilder.create().setProperties('storeBrand').setSortBy('storeBrand asc').setPageSize(100).setDistinct(true))).map(async brandItem => {; return ({ [`value`]: (getObjectProperty(brandItem, 'storeBrand')),[`label`]: (getObjectProperty(brandItem, 'storeBrand')) });})));
  console.log(getObjectProperty(___arguments.context.pageData, 'brandsOptions'));
  ___arguments.context.pageData['cities'] = (await Promise.all((await Backendless.Data.of('Stores').find(Backendless.DataQueryBuilder.create().setProperties('city.name, city.population, city.objectId').setSortBy('city.population desc').setGroupBy('city.name').setPageSize(100).setDistinct(true))).map(async storeItem => {; return ({ [`value`]: (getObjectProperty(storeItem, 'objectId')),[`label`]: (getObjectProperty(storeItem, 'name')) });})));
  console.log(getObjectProperty(___arguments.context.pageData, 'cities'));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/search/components/15a9adb742cc553b0599e1e8587cba78/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var storeItem, selectedCity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedCity = (getObjectProperty(___arguments.context.pageData, 'selectedCity'));
  ___arguments.context.pageData['storesOptions'] = (await Promise.all((await Backendless.Data.of('Stores').find(Backendless.DataQueryBuilder.create().setWhereClause((['storeBrand = \'',___arguments.value,'\' AND city = \'',selectedCity,'\''].join(''))).setProperties('name, address, objectId').setSortBy('address asc').setPageSize(100))).map(async storeItem => {; return ({ [`value`]: (getObjectProperty(storeItem, 'objectId')),[`label`]: [(getObjectProperty(storeItem, 'name')),' (',(getObjectProperty(storeItem, 'address')),')'].join('') });})));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/search/components/5dcb81e135a55dbd80e102e2b0be0178/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var storeItem, selectedStoreBrand, selectedCity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedStoreBrand = (getObjectProperty(___arguments.context.pageData, 'selectedStoreBrand'));
  ___arguments.context.pageData['storesOptions'] = (await Promise.all((await Backendless.Data.of('Stores').find(Backendless.DataQueryBuilder.create().setWhereClause((['storeBrand = \'',selectedStoreBrand,'\' AND city = \'',___arguments.value,'\''].join(''))).setProperties('name, address, objectId').setSortBy('address asc').setPageSize(100))).map(async storeItem => {; return ({ [`value`]: (getObjectProperty(storeItem, 'objectId')),[`label`]: [(getObjectProperty(storeItem, 'name')),' (',(getObjectProperty(storeItem, 'address')),')'].join('') });})));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/search/components/31b15ac74f78438bdbdbd1dbe27cd795/bundle.js', [], () => ({
  /* content */
  /* handler:onDecodeSuccess */
  async ['onDecodeSuccess'](___arguments) {
    var error, products, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.pageData['searchInput'] = ___arguments['decodedCode'];
    products = (await Promise.all((await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((String('barcode = ') + String(___arguments['decodedCode']))).setProperties(['objectId', 'name', 'amount']).setRelationsDepth(1).setPageSize(1))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: [(getObjectProperty(item, 'name')),', ',(getObjectProperty(item, 'amount')),(getObjectProperty(item, 'unit.name'))].join('') });})));
    ___arguments.context.pageData['productCollection'] = products;
    ___arguments.context.pageData['selectedProduct'] = (getObjectProperty(products, 'label'));
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('2a189df2359b5c3b6d013a9db8373f7a', (getObjectProperty((products[0]), 'value')));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDecodeSuccess */
  /* content */
}))

define('./pages/search/components/aa1d98d654f16d151cfc9f8fa8ab8277/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, item, product, products;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    product = (await Promise.all((await Backendless.Data.of('Products').find(Backendless.DataQueryBuilder.create().setWhereClause((['name LIKE \'%',(getObjectProperty(___arguments.context.pageData, 'searchInput')),'%\' ','AND barcode IS NULL'].join(''))).setRelationsDepth(1).setPageSize(10))).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: [(getObjectProperty(item, 'name')),', ',(getObjectProperty(item, 'amount')),(getObjectProperty(item, 'unit.name'))].join('') });})));
    ___arguments.context.pageData['productCollection'] = product;
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('2a189df2359b5c3b6d013a9db8373f7a', (getObjectProperty((products[0]), 'value')));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/search/components/42245ce49d2b54d8e6a7228b4aace7ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['prices'] = ((await Backendless.Data.of('Prices').find(Backendless.DataQueryBuilder.create().setWhereClause((['product = \'',(getObjectProperty(___arguments.context.pageData, 'selectedProduct')),'\' AND store = \'',(getObjectProperty(___arguments.context.pageData, 'selectedStore')),'\''].join(''))).setSortBy('created desc').setPageSize(1)))[0]);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedStore'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/d4c537b1541766c2eacb3fa3e5f8404e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'prices'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/2aae550db1d17f27938a3e1d7f9b475f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'productRbGroup')) == 'singleProductRb' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/08bd1daf80bd145e40d20ecbf857060f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'productRbGroup')) == 'productListRb' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/c8b6390fba54bce314152fa4956ad41f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'storeRbGroup')) == 'singleStoreRb' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/6411b072ee49fbfe868d90b5249926f8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'storeRbGroup')) == 'storeListRb' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/ce9f1cf673687c1aa9f3a3eed4fa2aba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'storeRbGroup')) == 'storesByDistanceRb' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/search/components/cd44670561ce4397db176f0337b15851/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var User;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  User = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['ProductListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteProducts', (getObjectProperty(User, 'objectId'))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/search/components/36cff539099d079ce1a961e6cb2b531e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var User;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  User = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['StoreListForUser'] = (await BackendlessUI.Functions.Custom['fn_0d12b2ba1f4f2bbc9ecc16e74798f99f']('FavoriteStores', (getObjectProperty(User, 'objectId'))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/search/components/e2d821a62b7aea35255da01237b8146e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('33384ac21aea4bac1d170b4e1698edce')), 'disp', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/search/components/19138532115eebfa2ed7eee2378af238/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['searchRadius'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/search/components/4e43e73d738785c685cd07ac5fca2121/bundle.js', [], () => ({
  /* content */
  /* handler:centerLogic */
  async ['centerLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ [`lat`]: (getObjectProperty((await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()), 'lat')),[`lng`]: (getObjectProperty((await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()), 'lng')) })

  },
  /* handler:centerLogic */
  /* handler:markersLogic */
  async ['markersLogic'](___arguments) {
      return [({ [`color`]: 'Green',[`description`]: 'Me',[`coordinates`]: (await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()) })]

  },
  /* handler:markersLogic */
  /* content */
}))

define('./pages/welcome/components/199703d241250599fd8c3c14fe514252/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;


  try {
    (await Backendless.UserService.getCurrentUser(false))['appOnboardStatus'] = 'Skipped';
    await Backendless.Data.of('Users').save((await Backendless.UserService.getCurrentUser(false)), true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/welcome/components/3a22833c8c76df7a456908f49f89918c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;


  try {
    (await Backendless.UserService.getCurrentUser(false))['appOnboardStatus'] = 'Completed';
    await Backendless.Data.of('Users').save((await Backendless.UserService.getCurrentUser(false)), true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/MainMobileLY/components/6c5c3b2fc124040df87792f89d5b62f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'phoneNumber')) == null ? 'SMSvalidation' : 'addPrice'), undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/MainMobileLY/components/5523c65d815ddb33fefd1fd8cdd9ad93/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('search', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/MainMobileLY/components/fa028e7734826853f50dd07d0758535b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('lists', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/MainMobileLY/components/708194385912a26a867511b419dd2743/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('profile', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/MainMobileLY/components/cc3ea192e2f4b61ccedb1f354945520f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/MainMobileLY/components/e05cfaa82063de982f79ab61a5c38781/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('/web/images/appLogos/') + String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'logoFileName')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/root/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {

  } catch (error) {
    console.log(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('3439e957aa29f0cead14054755ee4be8', true, false, undefined, (getObjectProperty(error, 'message')), 'warning');

  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./layouts/MainMobileLY/components/e5e9dfe564eacb44a0ec53ba4cf55337/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'home' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/4bbf3f6531b1d8d7a5f8d76e97bb6c54/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'addPrice' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/7aee0b3ee80770b2d242c50839115aa7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'search' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/e8c9515e0fcd81bdc81d57b6a24f988f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'lists' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/86a617ad2038b0c7abe19d9fe6ebc773/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'profile' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/45679b080c96c5d25eea800da8c8e509/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return ((((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('addPrice') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('addPriceHelp') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('leaderboard') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('pointsHelp') !== -1) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/48c77ffff7646f7a648771fb04e40bce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('leaderboard', undefined);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'leaderboard' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/8ced7021b84e85d12af996a6181f54eb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('addPriceHelp', undefined);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'addPriceHelp' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/7eddef7be395a9194e11dcad8bd89499/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('pointsHelp', undefined);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) == 'pointsHelp' ? ['active'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/3afec9af992c154f835965f44417ccf2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return ((((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('addPrice') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('addPriceHelp') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('leaderboard') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('pointsHelp') !== -1) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/48f7613c93f413359cc29c6160b6282d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return ((((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('addPrice') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('addPriceHelp') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('leaderboard') !== -1) || (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('pointsHelp') !== -1) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./layouts/MainMobileLY/components/475ac2bd835ddef932954c6896ed8f16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).open(event) })('dc1da6abbe24d7fffff1b43db7439568', undefined);

  },
  /* handler:onClick */
  /* content */
}))

